@import "./src/utils/styles/colors";
@import "./src/utils/styles/mixins";
@import "../CustomCalendar/calendar";

.schedule {
  width: 100%;
  @include flex($wrap: wrap, $align: stretch);

  &__item {
    margin: 20px 0;
    padding: 70px 0;
    background: $bg-main-color-transparent center/cover no-repeat;
    background-blend-mode: color;
    border: 4px solid $accent-color;
    width: 100%;
    @include flex($dir: column);

    @include respond(tablet) {
      max-width: 320px;
      min-height: 230px;
      margin: 30px 20px;
      padding: 50px 10px;
    }

    &:hover {
      background-color: $bg-main-color-transparent-dark;
      transition: 200ms;
    }
  }

  &__title {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 20px 0;
    letter-spacing: 1px;
    text-align: center;
  }

  &__date {
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__slots {
    letter-spacing: 1px;
    margin-top: 10px;
    font-weight: 700;
    color: $attention;
  }
}