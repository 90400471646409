@import "./src/utils/styles/mixins";
@import "./src/utils/styles/colors";

.services {
  color: $main-color;
  margin: 80px 0 50px;
  @include flex($dir: column);


  @include respond(tablet) {
    margin: 117px 0 50px;
  }

  @include respond(laptop) {
    margin: 200px 0 50px;
  }

  &__title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 50px 0;
    text-align: center;
    letter-spacing: 1px;

    @include respond(laptop) {
      font-size: 40px;
    }
  }

  &__item {
    margin: 15px auto;
    @include flex($dir: column);

    @include respond(tablet) {
      @include flex($align: flex-start);
      max-width: 900px;
    }

    &--right {
      @include respond(tablet) {
        @include flex($dir: row-reverse, $align: flex-start);
      }
    }
  }

  &__img {
    display: block;
    position: relative;
    width: 100%;
  }

  &__photo {
    position: relative;
    width: 90%;

    @include green-bg();

    @include respond(tablet) {
      min-width: 280px;
      max-width: 280px;
    }
  }

  &__info {
    margin-top: 20px;
    flex-grow: 1;
    @include respond(tablet) {
      margin: 0 30px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
    &--mt{
      margin-top: 15px;
    }
  }

  &__block{
    width: 100%;
  }
}