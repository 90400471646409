@import "./src/utils/styles/colors";
@import "./src/utils/styles/mixins";

.event-participants {

  &__item {
    width: 100%;
    margin-top: 10px;

    &:after {
      content: ('');
      display: block;
      height: 1px;
      width: 50%;
      margin: 10px auto 0;
      background-color: $main-color;
    }
  }

  &__name {
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__field {
    margin-bottom: 5px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    @include respond(tablet) {
      @include flex($justify: flex-start);
    }
  }

  &__subtitle {
    @include respond(tablet) {
      flex-basis: 25%;
      text-align: right;
      margin-right: 5px;
    }
  }

  &__value {
    text-align: center;
  }

  &__btn{
    font-size: 30px;
    margin-left: 10px;
    cursor: pointer;

    &:hover{
      color: $dark-accent-color;
    }
  }
}