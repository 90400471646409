@import './src/utils/styles/colors';
@import './src/utils/styles/mixins';

.main {
  width: 100%;
  min-height: 65vh;
  color: $main-color;

  padding-top: 80px;

  @include respond(tablet) {
    padding-top: 200px;
  }

  &__title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 50px;
    letter-spacing: 2px;
    text-align: center;

    @include respond(tablet) {
      text-align: left;
    }
  }

  &__description {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    white-space: pre-wrap;

    @include respond(tablet) {
      text-align: left;
      width: 80%;
      margin-bottom: 20px;
    }
  }


  &__info {
    @include respond(laptop) {
      @include flex();
    }
  }

  &__about {
    margin: 50px 0;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    @include respond(tablet) {
      text-align: left;
      margin-left: 30px;
    }

    &--center {
      text-align: center;
    }

    &--large {
      font-size: 30px;
    }
  }

  &__text {
    margin-top: 15px;
    color: $secondary-text-color;
  }

  &__edit {
    font-size: 30px;
  }
}