$accent-color: #ffcc33;
$accent-color-transparent: rgba(255, 204, 51, 0.7);
$dark-accent-color: #CC9F21;
$main-color: #ffffff;
$secondary-color: #000000;
//$secondary-text-color: #707070;
$secondary-text-color: #ccc;
$light-text-color: #eeeeee;
$bg-footer-color: #1b1b1b;
$bg-main-color: #1f1f1f;
$bg-main-color-transparent: rgba(0, 0, 0, 0.6);
$bg-main-color-transparent-dark: rgba(0, 0, 0, 0.9);
$viber-color: #7360F2;
$tg-color: #2AABEE;
$inst-color: #F77737;
$facebook-color: #4267B2;
$attention: #FA8072