* {
  box-sizing: border-box;

  &:before {
    box-sizing: border-box;
  }

  &:after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  min-height: 100vh;
  min-width: 300px;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input {
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
}

ul, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  padding: 0;
}

@for $i from 1 to 6 {
  h#{$i} {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}
