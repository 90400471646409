@import "colors";

@mixin respond($breakpoint) {
  @if $breakpoint==laptop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint==tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
}

@mixin flex($dir: row, $align: center, $justify: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin green-bg() {
  &::before {
    content: "";
    display: block;
    background-color: $accent-color;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 100%;
    height: 10px;
  }

  &::after {
    content: "";
    display: block;
    background-color: $accent-color;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 10px;
    height: calc(100% - 5px);
  }
}