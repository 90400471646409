@import "./src/utils/styles/colors";
@import "./src/utils/styles/mixins";

.contacts {
  width: 100%;
  padding: 0 20px;
  color: $main-color;
  margin: 80px 0 50px;
  @include flex($dir: column);

  @include respond(tablet) {
    margin-top: 117px;
  }

  @include respond(laptop) {
    margin-top: 200px;
  }

  &__title {
    font-weight: 700;
    text-align: center;
    margin: 50px 0 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 30px;

    @include respond(laptop) {
      font-size: 40px;
    }
  }

  &__address {
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin: 20px 0;
    text-align: center;
  }

  &__phone {
    @include flex();
    font-size: 18px;
    font-weight: 700;
  }

  &__mail {
    @include flex();
    font-size: 18px;
    margin-top: 20px;
  }

  &__icon {
    font-size: 25px;
    margin: 5px;

    &--accent {
      color: $accent-color;
    }
  }

  &__social {
    margin-top: 20px;
    font-size: 0;
    @include flex();
  }

  &__username {
    font-size: 16px;
  }
}